@mixin border-radius($topleft, $topright, $bottomright, $bottomleft) {
    border-top-left-radius: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
}

@mixin transition($transition...) { //Usage: @include transition (all .3s ease-in-out);
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
}

@mixin transform($val) { //Usage: @include transform(translateX(10px));
    -webkit-transform: $val;
    -moz-transform: $val;
    -o-transform: $val;
    -ms-transform: $val;
    transform: $val;
}

@mixin horizontal-gradient($from, $to, $stop: 100%) { //Usage: @include horizontal-gradient($color-primary, $color-secondary);
    background-color: $from;
    background-image: -moz-linear-gradient(left, $from 0%, $to $stop);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));
    background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);
    background-image: -o-linear-gradient(left, $from 0%, $to $stop);
    background: -ms-linear-gradient(left, $from 0%, $to $stop);
    background: linear-gradient(to right, $from 0%, $to $stop);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
}

@mixin vertical-gradient($from, $to) { //Usage: @include vertical-gradient($color-primary, $color-secondary);
    background: $to;
    background: -moz-linear-gradient(top, $from 0%, $to 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $from), color-stop(100%, $to));
    background: -webkit-linear-gradient(top, $from 0%, $to 100%);
    background: -o-linear-gradient(top, $from 0%, $to 100%);
    background: linear-gradient(to bottom, $from 0%, $to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to})
}

@mixin scale($val) { //Usage: @include scale(1.5);
    -webkit-transform: scale($val);
    -moz-transform: scale($val);
    -o-transform: scale($val);
    -ms-transform: scale($val);
    transform: scale($val);
}


;@import "sass-embedded-legacy-load-done:1366";