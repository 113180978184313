@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero {
    overflow: hidden;

    &.big {
        @include breakpoint(large down) {
            .inner .button {
                margin-top: 32px;

                @include breakpoint(small only) {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        @include breakpoint(xlarge) {
            min-height: 665px;

            .grid-x {
                padding-top: 96px;
            }

            .inner {
                position: relative;

                .button {
                    margin-top: 40px;
                }

                .scroll {
                    width: 100%;
                    margin-top: 24px;

                    &:after {
                        @include transform(rotate(90deg));
                    }
                }
            }
        }
    }

    @include breakpoint(xlarge) {
        &.small {
            min-height: 480px;

            .grid-x {
                padding-top: 48px;
                padding-bottom: 48px;
            }

            .button {
                margin-top: 16px;
            }
        }
    }

    .image {
        position: relative;

        @include breakpoint(xlarge) {
            position: absolute;
            width: 50vw;
            height: 100%;
            left: 50vw;
        }
    }

    img {
        width: 100%;

        @include breakpoint(xlarge) {
            width: 50vw;
            height: 100%;
            object-fit: cover;
            position: absolute;
            right: 0;
        }
    }

    @include breakpoint(large down) {
        .grid-x {
            padding-top: 40px;
            padding-bottom: 48px;
        }
    }

    .heading-h1 {
        margin-bottom: 24px;
    }

    .case-info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-left: 0;

        li {
            margin-right: 8px;
            display: flex;

            a {
                text-decoration: none;
            }
        }
    }

    .video-holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        z-index: 3;

        video {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .close-video {
            box-shadow: 3px 3px 3px 0px rgba($color-black, 0.25);
            cursor: pointer;
            width: 28px;
            height: 28px;
            position: absolute;
            top: 16px;
            right: 0;
            z-index: 2;

            @include breakpoint(xlarge) {
                right: 16px;
            }

            &:before {
                content: 'x';
                color: $color-white;
            }
        }
    }

    @include breakpoint(xlarge) {
        &.video-play {
            .image {
                width: 100vw;
                left: 0;
            }

            .button-play {
                left: 75%;
            }
        }
    }

    .kader-shape {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgdmlld0JveD0iMCAwIDEwMSAxMDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjY2MDE1NiAzNC4zMjJWNjcuNjQ2N1YxMDAuOTg0SDM0LjAwMjJWNjcuNjQ2N1YzNC4zMjJWMC45ODQzNzVIMC42NjAxNTZWMzQuMzIyWiIgZmlsbD0iIzAwRDg4MCIvPgo8cGF0aCBkPSJNNjcuMzMxIDM0LjMzNTlMMzQuMDAyIDY3LjY2MDZMNjcuMzMxIDEwMC45ODVMMTAwLjY2IDY3LjY2MDZMNjcuMzMxIDM0LjMzNTlaIiBmaWxsPSIjMDBEODgwIi8+Cjwvc3ZnPgo=");
        background-size: 100% auto;
        position: absolute;
        width: 160px;
        height: 160px;
        left: calc(50vw - 54px);
        transform: translateY(-100px);
        bottom: 0;
        z-index: 1;
    }

    .search-holder {
        background-color: $color-white;

        .search-holder {
            @include breakpoint(large down) {
                width: 100%;
                padding: 16px;
                position: fixed;
                top: 64px;
                left: 0;
                transform: translateX(102%);
                transition: transform 300ms ease-out;
            }

            @include breakpoint(xlarge) {
                margin-right: auto;
            }
        }

        .search {
            height: 49px;
            min-height: 49px;
            padding: 0 0 0 32px;
            display: flex;
            align-items: center;

            input {
                border: none;
                width: 100%;
                outline: 0;
            }

            button {
                cursor: pointer;
                padding: 0 24px;
                height: 100%;
                margin-left: auto;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:195";