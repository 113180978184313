@import "../../../src/scss/themes/opleiding/abstracts/custom_variables";
@import "_default/block_hero.scss";

.block_hero {
    background-color: $color-primary;

    &.big {
        @include breakpoint(large down) {
            .image:before {
                content: '';
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iODgiIHZpZXdCb3g9IjAgMCA4OCA4OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMjkuMzM3MlY1OC42NjI4Vjg4SDI5LjM0MVY1OC42NjI4VjI5LjMzNzJWMEgwVjI5LjMzNzJaIiBmaWxsPSIjMDBEODgwIi8+CjxwYXRoIGQ9Ik01OC42NzAzIDI5LjM0NzdMMjkuMzQwOCA1OC42NzMzTDU4LjY3MDMgODcuOTk5TDg3Ljk5OTkgNTguNjczM0w1OC42NzAzIDI5LjM0NzdaIiBmaWxsPSIjMDBEODgwIi8+Cjwvc3ZnPgo=");
                background-size: 100% auto;
                position: absolute;
                width: 88px;
                height: 88px;
                right: 16px;
                bottom: -20px;
                z-index: 1;
            }
        }
    }

    &.small {
        @include breakpoint(large down) {
            .image:before {
                content: '';
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDkiIHZpZXdCb3g9IjAgMCA0OCA0OSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0LjUgMjQuNDg0NEg0OC41VjAuNDg0NDg5SDI0LjVWMjQuNDg0NFoiIGZpbGw9IiMwMEQ4ODAiLz4KPHBhdGggZD0iTTAuNTAwMDA2IDQ4LjQ4NDRIMjQuNVYyNC40ODQ1SDAuNTAwMDA2VjQ4LjQ4NDRaIiBmaWxsPSIjMDAxRjMyIi8+CjxwYXRoIGQ9Ik0wLjUwMDAwNiAyNC40ODQ0TDI0LjUgMC40ODQ0ODlWMjQuNDg0NEgwLjUwMDAwNloiIGZpbGw9IiMwMDFGMzIiLz4KPC9zdmc+Cg==");
                background-size: 100% auto;
                position: absolute;
                width: 48px;
                height: 49px;
                right: 0;
                bottom: -1px;
                z-index: 1;
            }
        }

        @include breakpoint(xlarge) {
            &:before {
                content: '';
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzIiIGhlaWdodD0iNzMiIHZpZXdCb3g9IjAgMCA3MiA3MyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2IDM2Ljk4NDRINzJWMC45ODQ1NDNIMzZWMzYuOTg0NFoiIGZpbGw9IiMwMEQ4ODAiLz4KPHBhdGggZD0iTTcuNjI5MzllLTA2IDcyLjk4NDRIMzZWMzYuOTg0NUg3LjYyOTM5ZS0wNlY3Mi45ODQ0WiIgZmlsbD0iIzAwMUYzMiIvPgo8cGF0aCBkPSJNNy42MjkzOWUtMDYgMzYuOTg0NEwzNiAwLjk4NDU0M1YzNi45ODQ0SDcuNjI5MzllLTA2WiIgZmlsbD0iIzAwMUYzMiIvPgo8L3N2Zz4K");
                background-size: 100% auto;
                position: absolute;
                width: 72px;
                height: 72px;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
        }
    }

    .heading-h1,
    p {
        color: $color-white;
    }

    .scroll {
        color: $color-secondary-light;
    }

    .case-info {
        a {
            color: $color-secondary-light;
            font-size: $font-xxs;
        }

        .date {
            color: $color-black-32;
            font-size: $font-xxs;
        }
    }

    .close-video {
        background-color: $color-secondary-light;
    }

    .button-play:hover {
        background-color: $color-secondary-medium;
        @include transition(all 150ms ease-out-in);
    }

    ul {
        list-style-type: square;

        li {
            color: $color-white;
            position: relative;

            &::marker {
                color: $color-secondary-light;
            }
        }
    }

    .search {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjkyMDMgMTguMDkxTDExLjU5NzQgMTMuNzY3MkMxMC4xNDk3IDE0Ljc5NjQgOC4zNjgyIDE1LjI0NDEgNi42MDU5MSAxNS4wMjE3QzQuODQzNjIgMTQuNzk5NCAzLjIyOTIgMTMuOTIzMSAyLjA4MjYgMTIuNTY2NUMwLjkzNjAwOSAxMS4yMSAwLjM0MDkzIDkuNDcyMjEgMC40MTUzMDYgNy42OTc2MkMwLjQ4OTY4MSA1LjkyMzA0IDEuMjI4MDggNC4yNDExNSAyLjQ4NDE3IDIuOTg1M0MzLjczOTkxIDEuNzI4OSA1LjQyMTkxIDAuOTkwMTQgNy4xOTY3NSAwLjkxNTQ1QzguOTcxNTkgMC44NDA3NTkgMTAuNzA5NyAxLjQzNTU5IDEyLjA2NjYgMi41ODIwNEMxMy40MjM1IDMuNzI4NDkgMTQuMyA1LjM0Mjg2IDE0LjUyMjYgNy4xMDUxNkMxNC43NDUxIDguODY3NDYgMTQuMjk3MyAxMC42NDkgMTMuMjY4IDEyLjA5NjdMMTcuNTkwOSAxNi40MjA1TDE1LjkyMTUgMTguMDg5OEwxNS45MjAzIDE4LjA5MVpNNy40OTIzOCAzLjI3MDk5QzYuNDA2MjggMy4yNzA3NCA1LjM1MzI4IDMuNjQ0ODQgNC41MTA4MSA0LjMzMDI1QzMuNjY4MzMgNS4wMTU2NyAzLjA4Nzg4IDUuOTcwNTEgMi44NjcyNSA3LjAzMzlDMi42NDY2MiA4LjA5NzI5IDIuNzk5MjkgOS4yMDQyMiAzLjI5OTU1IDEwLjE2ODJDMy43OTk4IDExLjEzMjIgNC42MTcwNiAxMS44OTQyIDUuNjEzNjMgMTIuMzI2MUM2LjYxMDE5IDEyLjc1NzkgNy43MjUxNSAxMi44MzMgOC43NzA2NCAxMi41Mzg4QzkuODE2MTMgMTIuMjQ0NiAxMC43MjgzIDExLjU5OSAxMS4zNTMzIDEwLjcxMDhDMTEuOTc4NCA5LjgyMjcgMTIuMjc4MiA4Ljc0NjI2IDEyLjIwMjIgNy42NjI4OUMxMi4xMjYzIDYuNTc5NTIgMTEuNjc5MiA1LjU1NTQ0IDEwLjkzNjMgNC43NjMyTDExLjY1MDYgNS40NzE1M0wxMC44NDU0IDQuNjY4NzVMMTAuODMxMiA0LjY1NDU5QzEwLjM5MzkgNC4yMTQ1OSA5Ljg3MzU3IDMuODY1NzQgOS4zMDA0NSAzLjYyODI0QzguNzI3MzMgMy4zOTA3NCA4LjExMjc3IDMuMjY5MzEgNy40OTIzOCAzLjI3MDk5WiIgZmlsbD0iI0FEQURBRCIvPgo8L3N2Zz4=");
        background-size: 17px 17px;
        background-repeat: no-repeat;
        background-position: 8px center;
        border: $color-black-10 solid 1px;

        @include breakpoint(large down) {
            background-color: $color-white;
        }

        input,
        input::placeholder {
            color: $color-black-54;
            font-size: $font-s;
        }

        button {
            color: $color-black-54;
            font-size: $default-size;
            font-weight: $medium;

            &.close {
                background-color: $color-tertiary;
                color: $color-white;
            }
        }

        &:hover {
            border-color: $color-black-32;
        }
    }
}
